@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../../../libs/shared/assets/src/styles/shared-styles.scss';

@layer utilities {
  .bo-uf-content {
    @apply px-3 pb-11 pt-5 md:px-4 md:pt-8 xl:px-32;
  }
}

.link {
  @apply box-content inline-block transition-colors duration-200 ease-in hover:text-white;
}

//!IMPORTANT do NOT rename any of these
:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom); /* THIS ONE GETS US THE HOME BAR HEIGHT */
  --sal: env(safe-area-inset-left);
}

.cdk-overlay-pane {
  @apply pt-safe #{!important};
}
