/* Latin Light */
@font-face {
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/aeonik-light.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* Latin Regular */
@font-face {
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/aeonik-regular.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/aeonik-medium.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* Latin Bold */
@font-face {
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/aeonik-bold.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* Latin Black */
@font-face {
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/aeonik-black.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  font-family: 'Aeonik', sans-serif;
}

@layer utilities {
  .text {
    // Design System V2 Typography
    &-po {
      &-paragraph {
        &-underline {
          &-large,
          &-medium,
          &-small,
          &-x-small {
            font-style: normal;
            font-weight: 500;
            text-decoration-line: underline;
          }

          &-large {
            font-size: 18px;
            line-height: 28px; /* 155.556% */
          }

          &-medium {
            font-size: 16px;
            line-height: 24px; /* 150% */
          }

          &-small {
            font-size: 14px;
            line-height: 20px; /* 142.857% */
          }

          &-x-small {
            font-size: 12px;
            line-height: 16px; /* 133.333% */
          }

          &-xx-small {
            font-size: 8px;
            line-height: 16px;
          }
        }

        &-italic {
          &-large,
          &-medium,
          &-small,
          &-x-small,
          &-xx-small {
            font-style: italic;
            font-weight: 400;
          }

          &-large {
            font-size: 18px;
            line-height: 28px; /* 155.556% */
          }

          &-medium {
            font-size: 16px;
            line-height: 24px; /* 150% */
          }

          &-small {
            font-size: 14px;
            line-height: 20px; /* 142.857% */
          }

          &-x-small {
            font-size: 12px;
            line-height: 16px; /* 133.333% */
          }

          &-xx-small {
            font-size: 8px;
            line-height: 16px; /* 133.333% */
          }
        }

        &-regular {
          &-large,
          &-medium,
          &-small,
          &-x-small,
          &-xx-small {
            font-style: normal;
            font-weight: 400;
          }

          &-large {
            font-size: 18px;
            line-height: 28px;
          }

          &-medium {
            font-size: 16px;
            line-height: 24px;
          }

          &-small {
            font-size: 14px;
            line-height: 20px; /* 142.857% */
          }

          &-x-small {
            font-size: 12px;
            line-height: 16px; /* 133.333% */
          }

          &-xx-small {
            font-size: 8px;
            line-height: 16px; /* 133.333% */
          }
        }
      }

      &-label {
        &-large,
        &-medium,
        &-small,
        &-x-small {
          font-style: normal;
          font-weight: 500;
        }

        &-large {
          font-size: 18px;
          line-height: 28px; /* 155.556% */
        }

        &-medium {
          font-size: 16px;
          line-height: 24px; /* 150% */
        }

        &-small {
          font-size: 14px;
          line-height: 20px; /* 142.857% */
        }

        &-x-small {
          font-size: 12px;
          line-height: 16px; /* 133.333% */
        }
      }

      &-heading {
        &-xx-large,
        &-x-large,
        &-large,
        &-medium,
        &-small,
        &-x-small {
          font-style: normal;
          font-weight: 700;
        }

        &-xx-large {
          font-size: 40px;
          line-height: 52px; /* 130% */
        }

        &-x-large {
          font-size: 36px;
          line-height: 44px; /* 122.222% */
        }

        &-large {
          font-size: 32px;
          line-height: 40px; /* 125% */
        }

        &-medium {
          font-size: 28px;
          line-height: 36px; /* 128.571% */
        }

        &-small {
          font-size: 24px;
          line-height: 32px; /* 133.333% */
        }

        &-x-small {
          font-size: 20px;
          line-height: 28px; /* 140% */
          letter-spacing: 0.25px;
        }
      }

      &-display {
        &-large,
        &-medium,
        &-small,
        &-x-small {
          font-style: normal;
          font-weight: 700;
        }

        &-large {
          font-size: 96px;
          line-height: 108px; /* 112.5% */
        }

        &-medium {
          font-size: 52px;
          line-height: 64px; /* 123.077% */
        }

        &-small {
          font-size: 44px;
          line-height: 52px; /* 118.182% */
        }

        &-x-small {
          font-size: 36px;
          line-height: 44px; /* 122.222% */
        }
      }
    }

    // Design System V1 Typography
    &-heading {
      &-bold {
        font-weight: 700;
        line-height: 32px;
      }
    }

    &-display-1 {
      font-weight: 900;
      line-height: 100%;
    }

    &-display-2 {
      &-black {
        font-weight: 900;
        line-height: 100%;
      }

      &-bold {
        font-weight: 700;
        line-height: 100%;
      }
    }

    &-h-1,
    &-h-2,
    &-h-3,
    &-h-5 {
      font-weight: 800;
      line-height: 120%;
    }

    &-h-4 {
      &-bold {
        font-weight: 800;
        line-height: 120%;
      }

      &-regular {
        font-weight: 400;
        line-height: 120%;
      }
    }

    &-h-6 {
      font-weight: 500;
      line-height: 120%;
    }

    &-body {
      &-regular,
      &-small,
      &-small-regular {
        font-weight: 400;
        line-height: 120%;
      }

      &-small-bold {
        font-weight: 700;
        line-height: 120%;
      }

      &-medium {
        font-weight: 600;
        line-height: 120%;
      }

      &-bold {
        font-weight: 700;
        line-height: 120%;
      }
    }

    &-cta {
      &-small,
      &-medium,
      &-large {
        line-height: 120%;
        font-weight: 700;
      }
    }

    // SM 5
    @media (max-width: 766px) {
      &-display-1 {
        font-size: 4.81rem;
      }

      &-display-2-bold,
      &-display-2-black {
        font-size: 3.63rem;
      }

      &-h-1 {
        font-size: 2.56rem;
      }

      &-h-2 {
        font-size: 2.188rem;
      }

      &-h-3 {
        font-size: 1.813rem;
      }

      &-h-4-bold,
      &-h-4-regular {
        font-size: 1.44rem;
      }

      &-h-5 {
        font-size: 1.125rem;
      }

      &-h-6 {
        font-size: 1rem;
      }

      &-body {
        &-regular,
        &-medium,
        &-bold {
          font-size: 14px;
        }

        &-small,
        &-small-bold,
        &-small-regular {
          font-size: 12px;
        }
      }

      &-cta {
        &-small {
          font-size: 11px;
        }

        &-medium {
          font-size: 13px;
        }

        &-large {
          font-size: 16px;
        }
      }
    }

    // MD 4
    @media (min-width: 767px) and (max-width: 1198px) {
      &-display-1 {
        font-size: 5.37rem;
      }

      &-display-2-bold,
      &-display-2-black {
        font-size: 4.06rem;
      }

      &-h-1 {
        font-size: 2.88rem;
      }

      &-h-2 {
        font-size: 2.438rem;
      }

      &-h-4-bold,
      &-h-4-regular {
        font-size: 1.63rem;
      }

      &-h-3 {
        font-size: 2.063rem;
      }

      &-h-5 {
        font-size: 1.438rem;
      }

      &-h-6 {
        font-size: 1.125rem;
      }

      &-body {
        &-regular,
        &-medium,
        &-bold {
          font-size: 14px;
        }

        &-small,
        &-small-bold,
        &-small-regular {
          font-size: 12px;
        }
      }

      &-cta {
        &-small {
          font-size: 12px;
        }

        &-medium {
          font-size: 14px;
        }

        &-large {
          font-size: 18px;
        }
      }
    }

    // LG 3
    @media (min-width: 1199px) and (max-width: 1398px) {
      &-display-1 {
        font-size: 5.37rem;
      }

      &-display-2-bold,
      &-display-2-black {
        font-size: 4.06rem;
      }

      &-h-1 {
        font-size: 2.88rem;
      }

      &-h-2 {
        font-size: 2.438rem;
      }

      &-h-4-bold,
      &-h-4-regular {
        font-size: 1.63rem;
      }

      &-h-3 {
        font-size: 2.063rem;
      }

      &-h-5 {
        font-size: 1.25rem;
      }

      &-h-6 {
        font-size: 1.125rem;
      }

      &-body {
        &-regular,
        &-medium,
        &-bold {
          font-size: 14px;
        }

        &-small,
        &-small-regular,
        &-small-bold {
          font-size: 12px;
        }
      }

      &-cta {
        &-small {
          font-size: 12px;
        }

        &-medium {
          font-size: 14px;
        }

        &-large {
          font-size: 18px;
        }
      }
    }

    // XL 2
    @media (min-width: 1399px) {
      &-display-1 {
        font-size: 6.62rem;
      }

      &-display-2-bold,
      &-display-2-black {
        font-size: 5rem;
      }

      &-h-1 {
        font-size: 3.5rem;
      }

      &-h-2 {
        font-size: 3rem;
      }

      &-h-4-bold,
      &-h-4-regular {
        font-size: 2rem;
      }

      &-h-3 {
        font-size: 2.5rem;
      }

      &-h-5 {
        font-size: 1.5rem;
      }

      &-h-6 {
        font-size: 1.438rem;
      }

      &-body {
        &-regular,
        &-medium,
        &-bold {
          font-size: 1rem !important;
        }

        &-small {
          font-size: 0.875rem;
        }

        &-small-regular,
        &-small-bold {
          font-size: 12px;
        }
      }

      &-cta {
        &-small {
          font-size: 12px;
        }

        &-medium {
          font-size: 14px;
        }

        &-large {
          font-size: 18px;
        }
      }
    }
  }
}

// // XXL 1
// @media (min-width: 1899px) {
//   &-display-1 {
//     font-size: 9.62rem;
//   }
//
//   &-display-2-bold,
//   &-display-2-black {
//     font-size: 7.25rem;
//   }
//
//   &-h-1 {
//     font-size: 5.06rem;
//   }
//
//   &-h-2 {
//     font-size: 4.375rem;
//   }
//
//   &-h-4-bold,
//   &-h-4-regular {
//     font-size: 2.88rem;
//   }
//
//   &-h-5 {
//     font-size: 2.188rem;
//   }
//
//   &-h-6 {
//     font-size: 2rem;
//   }
//
//   &-body {
//     &-regular,
//     &-medium {
//       font-size: 1.438rem;
//     }
//     &-small {
//       font-size: 1.25rem;
//     }
//   }
// }
