@import '@angular/cdk/overlay-prebuilt.css';

@import './typography.scss';
@import './scroll.scss';

::-webkit-scrollbar-track {
  @apply bg-transparent shadow-none #{!important};
}

@layer components {
  :root {
      --swiper-pagination-color: #111111;
  }
}

::-webkit-scrollbar {
  @apply h-2 bg-po-gray-50;
}

.scrollbar-placeholder,
::-webkit-scrollbar {
  @apply w-1 xl:w-2;
}

::-webkit-scrollbar-thumb {
  @apply bg-po-black-300;
}

.global-scroll-block {
  @apply overflow-hidden #{!important};
}

.aw-backdrop {
  @apply bg-black bg-opacity-60 backdrop-blur;

  &--dark {
    @apply bg-black bg-opacity-90 backdrop-blur;
  }
}

@layer utilities {
  .uf-content {
    @apply mx-auto  w-full max-w-[351px] md:max-w-[736px] xl:max-w-uf-content;
  }

  .uf-content-2 {
    @apply w-full px-3 md:px-4 xl:mx-auto xl:max-w-[83%] xl:px-0;
  }

  .uf-content-final {
    @apply w-full px-4 md:px-16 xl:mx-auto xl:max-w-[81rem] xl:px-0;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.ctrl-placeholder {
  @apply pointer-events-none absolute;

  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1),
  color 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &--not-floating {
    @apply text-body-regular top-1/2 -translate-y-1/2  text-black;
  }

  &--floating {
    @apply text-body-small top-2 font-normal text-po-gray-1000;
  }
}

.ctrl-border {
  @apply origin-top-left border-1 border-solid border-po-grey-2 transition-colors duration-200 ease-in;

  @apply ring-0 #{!important};

  &:not(:disabled) &:hover,
  &:focus,
  &--focused {
    @apply border-black;
  }

  &--loading {
    @apply border-transparent;
  }

  &--error {
    @apply border-po-error-base;
  }
}

.hammer-fix {
  -webkit-touch-callout: default !important;
}

.cdk-overlay-container {
  @apply z-cover;
}